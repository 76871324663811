import React, { useState, useEffect, useRef, useCallback } from 'react';
import './Inicio.css';
import { Link } from 'react-router-dom';
import graduacion from '../Imagenes/GRADUACION.jpeg';
import graduacion2 from '../Imagenes/enfermeria_graduacion.jpeg';
import graduacion3 from '../Imagenes/Graduacion_enfermeria.jpeg';
import enfermeriaescuela from '../Imagenes/Enfermeria_escuela.jpeg';
import Nuevacarrera from '../Imagenes/escuela.jpg'
// import Nuevacarrera from '../Imagenes/CONOCER.png';
import Escuela from '../Imagenes/escuela.jpg';

function Inicio() {
  const [currentImage, setCurrentImage] = useState(0);
  const intervalRef = useRef(null);

  // Galería de imágenes
  const images = [
    { src: graduacion, caption: 'Evaluación del estado físico y psicológico del paciente' },
    { src: graduacion2, caption: 'Desarrollo de programas de salud' },
    { src: graduacion3, caption: 'Promoción de estilos de vida saludables' },
  ];

  // Función para avanzar a la siguiente imagen
  const nextImage = useCallback(() => {
    setCurrentImage((prevImage) => (prevImage + 1) % images.length);
  }, [images.length]);

  // Función para retroceder a la imagen anterior
  const prevImage = () => {
    setCurrentImage((prevImage) => (prevImage - 1 + images.length) % images.length);
  };

  const handleImageClick = (event) => {
    const imageWidth = event.target.clientWidth;
    const clickX = event.nativeEvent.offsetX;

    clearInterval(intervalRef.current); // Detenemos el temporizador al hacer clic
    startInterval(); // Reiniciamos el temporizador

    if (clickX < imageWidth / 2) {
      prevImage();
    } else {
      nextImage();
    }
  };

  // Función de intervalo usando useCallback
  const startInterval = useCallback(() => {
    intervalRef.current = setInterval(nextImage, 2000);
  }, [nextImage]);

  useEffect(() => {
    startInterval(); // Iniciamos el intervalo al cargar el componente
    return () => clearInterval(intervalRef.current); // Limpiamos el intervalo al desmontar
  }, [startInterval]);

  return (
    <div>
      {/* Sección de bienvenida */}
      <section className='bienvenida_inicio'>
        <h1 className='Titutlo'>Bienvenidos a CCEGYT</h1>
        <p>
          Formamos profesionales comprometidos con la salud desde 2019. 
        </p>
        <p>
        Ofrecemos programas especializados que combinan teoría y práctica, 
        brindando las herramientas necesarias para desarrollarse en el ámbito de la salud.
        </p>
        <div className="bienvenida">
          <div className="fotos">
            <img src={Escuela} alt="Instalaciones de la escuela" />
            <img src={enfermeriaescuela} alt="Clase de enfermería en acción" />
          </div>
        </div>
      </section>
      
      {/* Sección de nuevas carreras */}
      <section className="nuevas-carreras">
        <div className="noticia-imagen">
          <img src={Nuevacarrera} alt="Nuevas carreras disponibles" />
        </div>
        <div className="noticia-texto">
          <h2 className="titulo">Nuevas Carreras para el Próximo Año</h2>
          <p>¡Conoce nuestras nuevas ofertas académicas!</p>
          <ul className="oferta-educativa">
            <li>Diplomado en Terapias de Masaje</li>
            <li>Especialización en Gerontología</li>
            <li>Licenciatura en Enfermería</li>
            <li>Diplomado en Nutrición y Bienestar</li>
            <li>Certificación en Cuidado de la Salud Mental</li>
          </ul>
        </div>
      </section>

  
      {/* Sección de graduación con galería interactiva */}
      <section>
        <div className="graduacion">
          <div className="graduacion-texto">
          <h2 className="Titutlo">Graduación</h2>
            <p>
              Nuestros egresados están listos para el campo profesional con
              conocimientos actualizados y habilidades prácticas.
            </p>
            <blockquote>
              <p>
                "La salud es un estado de completo bienestar físico, mental y social,
                y no solamente la ausencia de afecciones o enfermedades."
              </p>
              <em>Organización Mundial de la Salud</em>
            </blockquote>
          </div>
          <div className="imagen-container" onClick={handleImageClick}>
            <img
              className="imagen-graduacion"
              src={images[currentImage].src}
              alt={`Imagen ${currentImage + 1}`}
            />
            <p>{images[currentImage].caption}</p>
          </div>
        </div>
      </section>
  
      {/* Sección de contacto */}
      <section className="contacto">
        <h2 className="Titutlo">Contáctanos</h2>
        <p>¿Tienes alguna duda? No dudes en comunicarte con nosotros.</p>
        <Link to="/contacto" className="btn">Contáctanos</Link>
      </section>
    </div>
  );
}

export default Inicio;
