import React from 'react';
import './LicenciaturaEnEnfermeria.css';
import temarioPDF from '../assets/temario_licenciatura_en_enfermeria.pdf';
import LicenciaturaEnfermeria from '../Imagenes/Licenciaturaenfermeria.jpeg';
import Licenciatura_enfermeria from '../Imagenes/Licenciatura_enfermeria.jpeg';
import Licenciatura_enfermeria2 from '../Imagenes/Licenciatura_enfermeria_practica.jpeg';

function LicenciaturaEnEnfermeria() {
  // Lista de imágenes
  const images = [
    { src: LicenciaturaEnfermeria, caption: 'Evaluación del estado físico y psicológico del paciente' },
    { src: Licenciatura_enfermeria, caption: 'Desarrollo de programas de salud' },
    { src: Licenciatura_enfermeria2, caption: 'Promoción de estilos de vida saludables' },
  ];

  return (
    <div className="licenciatura-en-enfermeria">
      <h1>Licenciatura en Enfermería: Transformando el Cuidado de la Salud.</h1>
      <p>
        La Licenciatura en Enfermería es una carrera universitaria que forma profesionales capacitados para brindar cuidados de salud a las personas.
      </p>
      <p>
        Conviértete en un profesional altamente capacitado en el área de la salud. Nuestra Licenciatura en Enfermería te preparará para cuidar y transformar vidas a través de conocimientos médicos avanzados y un enfoque humanitario.
      </p>
      <ul>
        <li>Evaluar integralmente el estado físico, mental y social del paciente, priorizando su bienestar.</li>
        <li>Realizar diagnósticos clínicos y establecer tratamientos enfocados en la recuperación y prevención.</li>
        <li>Aplicar estrategias efectivas para la prevención de enfermedades.</li>
        <li>Responder a emergencias y practicar procedimientos avanzados de primeros auxilios.</li>
        <li>Promover un estilo de vida saludable, educando a pacientes y comunidades.</li>
        <li>Diseñar y ejecutar programas de salud y bienestar, impactando positivamente en tu entorno.</li>
      </ul>

      {/* Galería de imágenes */}
      <div className="galeria-imagenes">
        {images.map((image, index) => (
          <div key={index} className="imagen-container">
            <img src={image.src} alt={`Imagen ${index + 1}`} className="imagen-carrusel" />
            <p className="caption">{image.caption}</p>
          </div>
        ))}
      </div>

      {/* Descarga del PDF con el temario */}
      <div className="temario">
        <h2>Descargar Temario</h2>
        <a href={temarioPDF} download="Temario_Licenciatura_Enfermeria.pdf">
          <button className="btn-descargar">Descargar PDF</button>
        </a>
      </div>

      {/* Botón de contacto */}
      <div className="contacto">
        <button className="btn-contacto" onClick={() => window.location.href = '/contacto'}>
          Contáctanos
        </button>
      </div>
    </div>
  );
}

export default LicenciaturaEnEnfermeria;
