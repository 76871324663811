// src/componentes/Contacto.js
import React from 'react';
import { FaFacebook, FaInstagram, FaWhatsapp } from 'react-icons/fa';
import './Contacto.css'; // Opcional: Crea este archivo para estilos personalizados

function Contacto() {
  return (
    <div className="contacto-page">
      <section className='contacto-map-ubi'>
        {/* Texto de Contacto Inicial */}
        <section className="contacto-intro">
          <h1>Contacto</h1>
          <p>Si tienes alguna pregunta o deseas más información, no dudes en contactarnos. Estamos aquí para ayudarte.</p>
        </section>

        {/* Sección de Ubicación con Google Maps */}
        <section className="ubicacion-section">
          <div className="mapa-container">
            <iframe
              title="Mapa de Ubicación"
              src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3779.5707371947524!2d-99.11994382480432!3d18.683249182442164!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTjCsDQwJzU5LjciTiA5OcKwMDcnMDIuNSJX!5e0!3m2!1sen!2smx!4v1729226402372!5m2!1sen!2smx"
              width="600"
              height="300"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>
          <div className="ubicacion-info">
            <h2>Ubicación</h2>
            <p>
              Dirección de la Escuela:<br />
              5 de febrero #64,<br />
              Tlaltizapan, Código Postal: 62770.
            </p>
          </div>
        </section>
      </section>
      
      {/* Sección de Redes Sociales */}
      <section className="redes-sociales-section">
        <h2>Síguenos en Redes Sociales</h2>
        <div className="redes-sociales">
          {/* Facebook */}
          <a href="https://www.facebook.com/p/CCEGYT-100075525594421/" target="_blank" rel="noopener noreferrer" className="red-social">
            <FaFacebook size={50} color="#3b5998" />
            <span>Facebook</span>
          </a>
          {/* Instagram */}
          <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" className="red-social">
            <FaInstagram size={50} color="#C13584" />
            <span>Instagram</span>
          </a>
          {/* WhatsApp */}
          <a href="https://wa.me/7351593396" target="_blank" rel="noopener noreferrer" className="red-social">
            <FaWhatsapp size={50} color="#25D366" />
            <span>WhatsApp</span>
          </a>
        </div>
      </section>
    </div>
  );
}

export default Contacto;
